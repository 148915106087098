import Text from '@hulu/web-ui/Text';
import '../../Library.scss';
import React from 'react';

const PosterContentWithHover = ({ poster }) => {
  const {
    eyebrow: posterEyebrow,
    headline: posterHeadline,
    isLibraryPosterAutomated,
    contentTitle,
    image,
  } = poster;

  const { alt = '', title = '', url = '', brandUrl = '', brand = '', hue = 0 } =
    image || {};
  const BASE_COLOR = {
    h: hue,
    s: 50,
    l: 40,
  };
  const baseColor = [BASE_COLOR.h, BASE_COLOR.s, BASE_COLOR.l];

  const hsla = ([h, s, l], alpha = 1) => {
    return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
  };
  const getBackgroundGradientStyle = () => {
    const colorFrom = hsla(baseColor, 1);

    return {
      opacity: 1.0,
      background: `linear-gradient(0deg, ${colorFrom}, transparent)`,
      position: 'absolute',
    };
  };

  return (
    <div className="library__item-wrapper library__item-wrapper--automated">
      <div className="library__item-gradient" />

      {isLibraryPosterAutomated && (
        <div
          className="library__item-scrim"
          style={getBackgroundGradientStyle()}
        />
      )}

      <img
        className="library__item-background lazyload"
        src={url}
        alt={alt}
        loading="lazy"
      />
      <div className="library__item-overlay">
        <Text
          as="span"
          breakpoints={{
            sm: 'eyebrow12',
          }}
          className="library__item-eyebrow"
          data-automationid="library_item_eyebrow"
        >
          {posterEyebrow.toUpperCase()}
        </Text>
        <Text
          as="h3"
          breakpoints={{
            sm: 'title32',
          }}
          className="library__item-category"
          data-automationid="library_item_category"
          style={{ fontWeight: 600 }}
        >
          {posterHeadline}
        </Text>

        <Text
          as="h4"
          breakpoints={{
            sm: 'body14',
          }}
          className="library__item-title"
        >
          {title || contentTitle}
        </Text>
      </div>
      {brandUrl && (
        <img
          src={brandUrl}
          className="library__item-background-brand-logo"
          alt={brand}
          role="presentation"
        />
      )}
    </div>
  );
};

export default PosterContentWithHover;
