import Text from '@hulu/web-ui/Text';
import React, { useState, useRef } from 'react';

import PosterContentWithHover from './PosterContentWithHover';

import '../../Library.scss';
import ClientOnlyPortal from '!app/components/ClientOnlyPortal';
import CollectionsModal from '!app/components/CollectionsModal/views/CollectionsModal';
import { fireUtagLink, fireUserInteraction } from '!app/metrics/fireEvent';

const Poster = ({ poster, arePostersAutomated }) => {
  const {
    eyebrow: posterEyebrow,
    headline: posterHeadline,
    modalId,
    collectionsModalId,
    disclaimer,
    image,
  } = poster;

  const { alt = '', url = '', collectionModalUrlObject = {}, brandUrl = '' } =
    image || {};
  const [open, setOpen] = useState(false);
  const posterButton = useRef(null);

  const closeModal = () => {
    fireUserInteraction(
      'default',
      'collection_tile:modal:close',
      'click',
      false,
      posterHeadline
    );
    setOpen(false);
    posterButton.current.focus();
  };
  return (
    <>
      <div
        className="library__grid-item"
        data-automationid={`library_grid_item_shows_modal_${modalId}`}
        style={
          arePostersAutomated ? { margin: '0 auto', textAlign: 'center' } : null
        }
      >
        <button
          onClick={() => {
            fireUtagLink({ event_name: `click_${modalId}_tile` });
            fireUserInteraction(
              'default',
              'collection_tile:modal:open',
              'click',
              false,
              posterHeadline
            );
            setOpen(true);
          }}
          data-target={collectionsModalId ? null : `#${modalId}`}
          ref={posterButton}
        >
          {arePostersAutomated ? (
            <PosterContentWithHover poster={poster} />
          ) : (
            <div className="library__item-wrapper">
              <div className="library__item-gradient" />
              <img
                className="library__item-background"
                src={url}
                alt={alt}
                loading="lazy"
              />
              <div className="library__item-overlay">
                <Text
                  as="span"
                  breakpoints={{ xs: 'subtitle12', lg: 'subtitle16' }}
                  className="library__item-eyebrow"
                  data-automationid="library_item_eyebrow"
                >
                  {posterEyebrow}
                </Text>
                <Text
                  as="h3"
                  breakpoints={{ xs: 'title16', lg: 'title24' }}
                  className="library__item-category"
                  data-automationid="library_item_category"
                >
                  {posterHeadline}
                </Text>
              </div>
            </div>
          )}
        </button>

        {disclaimer && (
          <Text
            as="span"
            className="library__item-legal section-disclaimer"
            variant="body10"
          >
            {disclaimer}
          </Text>
        )}
      </div>
      {open && collectionsModalId && (
        <ClientOnlyPortal selector="#modal">
          <CollectionsModal
            collectionsModalId={collectionsModalId}
            arePostersAutomated={arePostersAutomated}
            collectionModalUrlObject={collectionModalUrlObject}
            brandUrl={brandUrl}
            close={() => closeModal()}
          />
        </ClientOnlyPortal>
      )}
    </>
  );
};

export default Poster;
