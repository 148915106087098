import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import CloseButtonSVG from '!app/components/Modal/assets/CloseButtonSVG';
import { mobileDetect } from '!app/lib/environment';
import { fireUserInteraction } from '!app/metrics';
import CTAButton from '!app/share/CTAButton';
import '../stylesheet/ExitIntentModal.scss';

const MODAL_TYPE = {
  TIME: 'time',
  EXIT_INTENT: 'exit_intent',
};
const SCROLL_PERCENTAGE_THRESHOLD = 0.0001;

const ModalCloseButton = (props) => {
  return (
    <button
      className="modal--close"
      data-automationid="exit_intent_modal_close"
      {...props}
    >
      <CloseButtonSVG />
    </button>
  );
};
const ExitIntentModal = (props) => {
  const {
    model: {
      id,
      image,
      eyebrow,
      headline,
      subheadline,
      primaryCopy,
      ctaCopy,
      ctaHref,
      ctaButtonStyle,
      legalCopy,
      backgroundColor,
      borderColor,
      // backgroundOpacity,
      hasRoundedCorners,
      isBackgroundBlurred,
      modalTriggerType,
      timeTriggerDelay,
      shouldTriggerOnCursorExit,
      shouldTriggerOnTabSwitch,
      shouldTriggerOnMobileScrollUp,
    },
    shown,
    toggleModal,
  } = props;

  const [hasShown, setHasShown] = useState(false);

  useEffect(() => {
    if (modalTriggerType === MODAL_TYPE.TIME) {
      setTimeout(() => {
        toggleModal(id);
      }, timeTriggerDelay);
    } else {
      if (shouldTriggerOnMobileScrollUp) {
        const isMobile = mobileDetect().mobile() && !mobileDetect().tablet();
        if (isMobile) {
          window.addEventListener('scroll', onMobileScrollUp);
        }
      }
      if (shouldTriggerOnCursorExit) {
        window.addEventListener('mouseout', onMouseOut);
        window.addEventListener('mouseleave', onMouseOut);
      }
      if (shouldTriggerOnTabSwitch) {
        window.addEventListener('visibilitychange', onBrowserTabSwitch);
      }
    }

    return () => {
      window.addEventListener('scroll', onMobileScrollUp);
      window.removeEventListener('mouseout', onMouseOut);
      window.removeEventListener('mouseleave', onMouseOut);
      window.removeEventListener('visibilitychange', onBrowserTabSwitch);
    };
  }, [
    modalTriggerType,
    shouldTriggerOnMobileScrollUp,
    shouldTriggerOnCursorExit,
    shouldTriggerOnTabSwitch,
    hasShown,
  ]);

  const onMobileScrollUp = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = window.innerHeight * SCROLL_PERCENTAGE_THRESHOLD;
    if (!shown && !hasShown && scrollPosition < scrollThreshold) {
      setHasShown(true);
      toggleModal(id);
    }
  };

  const onMouseOut = (event) => {
    const mouseXPostion = event.clientX;
    const mouseYPosition = event.clientY;
    if (
      !shown &&
      !hasShown &&
      (mouseYPosition < 0 ||
        mouseXPostion < 0 ||
        mouseXPostion >= window.innerWidth ||
        mouseYPosition >= window.innerHeight)
    ) {
      setHasShown(true);
      toggleModal(id);
    }
  };

  const onBrowserTabSwitch = () => {
    // if user changes tab, show modal
    if (document.hidden && !hasShown) {
      setHasShown(true);
      toggleModal(id);
    }
  };

  const onCloseModal = () => {
    toggleModal(id);
  };

  const onCtaClick = () => {
    fireUserInteraction('modal', 'driver_click', 'click', true, ctaCopy);
    // redirect to URL
    window.location.assign(ctaHref);
  };

  return (
    <div
      className={classnames('ExitIntentModal__wrapper', {
        '--show': shown,
        '--hide': !shown,
      })}
      id="exit-intent-modal"
      role="dialog"
      tabIndex="-1"
      aria-label="Modal has opened"
      aria-hidden={!shown}
    >
      <div className="ExitIntentModal__backdrop" onClick={onCloseModal} />
      <div
        className={classnames(
          'ExitIntentModal__body',
          `--background-color-${backgroundColor}`,
          `--border-color-${borderColor}`,
          {
            '--rounded-corners': hasRoundedCorners,
            '--blurred-background': isBackgroundBlurred,
          }
        )}
      >
        <div className="ExitIntentModal__header">
          <ModalCloseButton onClick={onCloseModal} aria-label="Close modal" />
        </div>
        {image && (
          <div className="ExitIntentModal__image">
            <img
              src={image?.source}
              role="presentation"
              data-automationid="exit_intent_modal_image"
              loading="lazy"
            />
          </div>
        )}
        {eyebrow && (
          <div
            className="ExitIntentModal__preheadline"
            data-automationid="exit_intent_modal_preheadline"
            dangerouslySetInnerHTML={{ __html: eyebrow }}
          />
        )}
        {headline && (
          <div
            className="ExitIntentModal__headline"
            data-automationid="exit_intent_modal_headline"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        )}
        {subheadline && (
          <div
            className="ExitIntentModal__subheadline"
            data-automationid="exit_intent_modal_subheadline"
            dangerouslySetInnerHTML={{ __html: subheadline }}
          />
        )}
        {primaryCopy && (
          <div
            className="ExitIntentModal__primary"
            data-automationid="exit_intent_modal_primary"
            dangerouslySetInnerHTML={{ __html: primaryCopy }}
          />
        )}
        {ctaCopy && ctaHref && (
          <CTAButton
            useStyle={ctaButtonStyle}
            className={classnames('ExitIntentModal__cta', {
              '--green': ctaButtonStyle === 'green',
            })}
            size="medium"
            onClick={onCtaClick}
            data-automationid="exit_intent_modal_input_cta"
          >
            {ctaCopy}
          </CTAButton>
        )}
        {legalCopy && (
          <div
            className="ExitIntentModal__legal"
            data-automationid="exit_intent_modal_legal"
            dangerouslySetInnerHTML={{ __html: legalCopy }}
          />
        )}
      </div>
    </div>
  );
};

export default ExitIntentModal;
