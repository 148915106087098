import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';

import { PlanCardModelSchema } from '../model/schema';

import { preventFocus, enterKeyHandler } from '!app/lib/accessibilityUtils';
import {
  TOOLTIP_PLACEHOLDER,
  ANON,
  PARTIALLY_ENTITLED,
  FULLY_ENTITLED,
} from '!app/lib/constants';
import { getDeviceType } from '!app/lib/deviceUtils';
import {
  getCtaText,
  getPremiumNetworkFromParameter,
} from '!app/lib/signupUtils';
import {
  getEventNameFromEntitlement,
  getProductName,
} from '!app/lib/TealiumEventsUtils';
import { ctaRedirect } from '!app/lib/urlUtils';
import { forceToArray } from '!app/lib/utils';
import {
  withUserInteraction,
  withUtagLink,
  WithEvents,
} from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';
import { DetailEntityStoreSchema } from '!app/share/schema';
import '../stylesheet/PlanCard.scss';

/**
 * Renders a single plan card. Meant to be used with PlansContainer.
 *
 * @see PlansContainer
 */
const PlanCard = (props) => {
  const [mouseEntered, setMouseEntered] = useState(false);
  const [showExpandedSection, setShowExpandedSection] = useState(false);
  const [showExpandedMobileSection, setShowExpandedMobileSection] = useState(
    false
  );
  const [planCardEvents, setPlanCardEvents] = useState(null);
  const {
    model: {
      highlight,
      id,
      price: { displayPrice, frequencyOverride },
      label,
      headline,
      subheadline,
      features,
      addon_headline,
      open_addons,
      close_addons,
      text_addons,
      cta_button_style,
      below_cta,
      mobile_button,
      mobile_close,
      ctaTextStates,
      ctaActionStates,
      program,
    },
    user,
    ctaFields,
    someCardIsHighlighted,
    locale,
    isOneUpPlan,
    cartAbandonment,
    network,
    requirePremium,
    onMouseEnteredOrLeft,
  } = props;

  useEffect(() => {
    const deviceType = getDeviceType();
    const events =
      deviceType === 'Phone' || deviceType === 'Tablet'
        ? {}
        : {
            onMouseEnter: toggleMouseEntered,
            onMouseLeave: toggleMouseLeave,
          };
    setPlanCardEvents(events);
  }, []);

  useEffect(() => {
    onMouseEnteredOrLeft(mouseEntered);
  }, [mouseEntered]);

  /**
   * Redirect user to signup or addons.
   *
   * @param {Event} e
   */
  const redirectToSignup = (e) => {
    e.stopPropagation();

    let from = null;
    if (program) {
      from = program;
    } else if (requirePremium && network?.length) {
      from = getPremiumNetworkFromParameter(requirePremium, network);
    }

    const actionUrl = ctaActionStates[user.entitlementState];
    const ctaOptions = {
      user,
      componentUrl: actionUrl,
      ctaFields,
      from,
      cartAbandonment,
    };

    ctaRedirect(ctaOptions);
  };

  /**
   * Toggles the state of the card and sends it to the PlansContainer.
   */
  const toggleMouseEntered = () => {
    setMouseEntered(!mouseEntered);
  };

  /**
   * Toggles the state of the card and sends it to the PlansContainer.
   */
  const toggleMouseLeave = () => {
    setMouseEntered(false);
  };

  /**
   * Toggles the state of the expanded section.
   */
  const toggleExpandedSection = () => {
    setShowExpandedSection(!showExpandedSection);
  };

  /**
   * Toggles the state of the expanded section.
   */
  const toggleExpandedMobileSection = () => {
    setShowExpandedMobileSection(!showExpandedMobileSection);
  };

  const ctaTextOptions = {
    user,
    program_type: null,
    componentNonSubCta: ctaTextStates[ANON],
    componentSubCta: ctaTextStates[PARTIALLY_ENTITLED],
    componentAllCta: ctaTextStates[FULLY_ENTITLED],
    locale,
    ctaFields,
    cartAbandonment,
  };
  const cta = getCtaText(ctaTextOptions);

  const priceArray = displayPrice?.split('/');
  const amount = priceArray?.[0];
  const frequency = frequencyOverride || priceArray?.[1];

  let DriverButton = withUserInteraction(CTAButton, 'cta_plans');
  DriverButton = withUtagLink(
    {
      event_name: getEventNameFromEntitlement(user),
      cta_placement: 'plan_card',
      product_name: getProductName(id),
    },
    DriverButton
  );

  const ctaButtonStyle = cta_button_style;

  const planCard = classNames(
    'plan-card',
    {
      'plan-card--highlight':
        (highlight && !someCardIsHighlighted) || mouseEntered,
    },
    { 'plan-card--one-up': isOneUpPlan }
  );
  const planCardExpandedSection = classNames(
    'plan-card__expanded-section',
    showExpandedSection ? 'plan-card__expanded-section--open' : false,
    { 'plan-card__expanded-section--open': isOneUpPlan }
  );
  const planCardWrapper = classNames('plan-card__wrapper', {
    'plan-card__wrapper--one-up': isOneUpPlan,
  });
  const planCardMobileWrapper = classNames(
    'plan-card__mobile-wrapper',
    showExpandedMobileSection ? 'plan-card__mobile-wrapper--open' : false,
    { 'plan-card__mobile-wrapper--open': isOneUpPlan }
  );
  const planCardExpandButton = classNames(
    'plan-card__expand-button',
    showExpandedSection ? 'plan-card__expand-button--open' : false
  );
  const planCardMobileExpandButton = classNames(
    'plan-card__mobile-expand-button',
    showExpandedMobileSection ? 'plan-card__mobile-expand-button--open' : false
  );

  const showAddOns = (addon) => {
    if (!addon.includes(TOOLTIP_PLACEHOLDER)) return addon;
    const indexOfDataTarget = addon.indexOf(TOOLTIP_PLACEHOLDER);
    const modalDataTarget = addon.slice(indexOfDataTarget, addon.length);
    const modalAddonTitle = addon.slice(0, indexOfDataTarget);
    return (
      <>
        <Text variant="body16">{addon.replace(modalDataTarget, '')}</Text>
        <button
          aria-label={`More Information about ${modalAddonTitle}`}
          className="button-link"
          data-target={modalDataTarget}
        >
          <img
            className="plan-card__tooltip"
            role="presentation"
            loading="lazy"
          />
        </button>
      </>
    );
  };

  return (
    <div className={planCard} {...planCardEvents}>
      <div className="plan-card__border" />
      <div className={planCardWrapper}>
        <div className="plan-card__header">
          {label && (
            <div className="plan-card__label">
              <Text
                as="p"
                variant="eyebrow12"
                className="plan-card__label-text"
              >
                {label}
              </Text>
            </div>
          )}
          <Text
            as="h3"
            data-automationid="plan_card_header"
            breakpoints={{ xs: 'label14', lg: 'label16' }}
          >
            {headline}
          </Text>
          {subheadline && (
            <p dangerouslySetInnerHTML={{ __html: subheadline }} />
          )}
          <div className="plan-card__priceline">
            <Text
              as="p"
              breakpoints={{ xs: 'title32', md: 'title40' }}
              className="plan-card__amount"
            >
              {amount}/
            </Text>
            <Text as="p" variant="body16">
              {frequency}
            </Text>
          </div>
          <div className="plan-card__button-wrapper">
            <DriverButton
              className="plan-card__cta"
              useStyle={ctaButtonStyle}
              onClick={redirectToSignup}
              aria-label={`Select plan - ${headline} for ${amount} USD per ${frequency}`}
              fullWidth
            >
              {cta}
            </DriverButton>
          </div>
          <div className="plan-card__sublink">
            {below_cta && (
              <WithEvents.div dangerouslySetInnerHTML={{ __html: below_cta }} />
            )}
          </div>
        </div>
        <div className={planCardMobileWrapper}>
          <div className="plan-card__list section-body">
            <ul>
              {features &&
                forceToArray(features).map((feature, index) => (
                  <li key={index}>
                    <span
                      className="plan-card__list-check"
                      role="presentation"
                    />
                    <Text variant="body16">{feature}</Text>
                  </li>
                ))}
            </ul>
          </div>
          <div className={planCardExpandedSection}>
            <div className="plan-card__expanded-heading">
              <Text variant="eyebrow14">{addon_headline}</Text>
            </div>
            <div className="plan-card__addons">
              <ul>
                {text_addons &&
                  forceToArray(text_addons).map((addon, index) => (
                    <li key={index}> {showAddOns(addon)} </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        {!isOneUpPlan && (
          <div className="plan-card__expander" aria-hidden="true">
            <button
              tabIndex="0"
              aria-label={
                showExpandedSection
                  ? close_addons
                  : `${open_addons} for ${headline} plan`
              }
              className={planCardExpandButton}
              onClick={toggleExpandedSection}
              onKeyDown={enterKeyHandler(toggleExpandedSection)}
              onMouseDown={preventFocus}
            >
              {showExpandedSection ? close_addons : open_addons}
              &nbsp;
              <span className="plan-card__icon-plus" />
            </button>
            <button
              className={planCardMobileExpandButton}
              onClick={toggleExpandedMobileSection}
            >
              {showExpandedMobileSection ? mobile_close : mobile_button}
              &nbsp;
              <span className="plan-card__icon-plus-mobile" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  model: PlanCardModelSchema.isRequired,
  user: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  someCardIsHighlighted: PropTypes.bool,
  onMouseEnteredOrLeft: PropTypes.func.isRequired,
  locale: PropTypes.string,
  isOneUpPlan: PropTypes.bool,
  cartAbandonment: PropTypes.shape({}),
  ...DetailEntityStoreSchema,
};

export default PlanCard;
