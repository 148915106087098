import PropTypes from 'prop-types';
import React from 'react';

import { getPageType } from '!app/lib/TealiumEventsUtils';
import { normalize } from '!app/lib/utils';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';

const FooterSocial = ({ asPath, pageType }) => {
  const socialLinks = [
    {
      label: 'Hulu Facebook',
      link: '//www.facebook.com/hulu',
      img: '/static/hitch/static/icons/facebook.svg',
    },
    {
      label: 'Hulu X',
      link: '//x.com/hulu',
      img: '/static/hitch/static/icons/x_logo.svg',
    },
    {
      label: 'Hulu Youtube',
      link: '//www.youtube.com/channel/UCE5mQnNl8Q4H2qcv4ikaXeA',
      img: '/static/hitch/static/icons/youtube.svg',
    },
    {
      label: 'Hulu Instagram',
      link: '//www.instagram.com/hulu',
      img: '/static/hitch/static/icons/instagram.svg',
    },
  ];
  const tealiumPageType = getPageType(asPath, pageType);

  return (
    <div>
      {socialLinks.map((section, index) => {
        const SocialItem = withUtagLink(
          {
            event_name: 'footer_link',
            event_label: section.link,
            page_type: tealiumPageType,
          },
          withUserInteraction('a', 'footer', normalize(section.label))
        );
        return (
          <SocialItem
            key={index}
            href={section.link}
            target="_blank"
            rel="noopener noreferrer nofollow"
            aria-label={section.label}
            title={section.label}
          >
            <img src={section.img} role="presentation" loading="lazy" />
          </SocialItem>
        );
      })}
    </div>
  );
};

FooterSocial.propTypes = {
  asPath: PropTypes.string,
  pageType: PropTypes.string,
};

export default FooterSocial;
