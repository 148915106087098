import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import { ValuePropModelSchema } from '../model/schema';

import { WithEvents, withUserInteraction } from '!app/metrics/hoc';

import '../stylesheet/ValuePropStyle.scss';

class ValueProp extends Component {
  constructor(props) {
    super(props);

    this.hashScrollTo = this.hashScrollTo.bind(this);
  }

  hashScrollTo() {
    const { model: { linkUrl } = {} } = this.props;
    if (this.isHashLink) {
      scroller.scrollTo(linkUrl.replace('#', ''), {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: -76,
      });
    }
  }

  render() {
    const {
      model: { style, icon, eyebrow, headline, bodyCopy, linkUrl, linkText },
      uniqueKey,
    } = this.props;

    const ValuePropButton = withUserInteraction(
      WithEvents.a,
      `value_prop_${uniqueKey + 1}`,
      'click'
    );
    this.isHashLink = linkUrl && linkUrl.startsWith('#');

    return (
      <div
        className={`value-prop--item ${style}`}
        role="region"
        aria-label={`${headline || eyebrow}`}
        data-automationid={`value_prop_item_${uniqueKey}`}
      >
        {icon && (
          <div
            className="value-prop--item-image"
            data-automationid="value_prop_item_image"
          >
            <img
              data-src={icon.url}
              className="value-prop--icon lazyload"
              alt={icon.alt}
              loading="lazy"
            />
          </div>
        )}

        <div className="value-prop--item-details">
          {eyebrow && (
            <WithEvents.span
              className="value-prop--eyebrow"
              dangerouslySetInnerHTML={{ __html: eyebrow }}
              data-automationid="value_prop_eyebrow"
            />
          )}
          {headline && (
            <WithEvents.h2
              className="value-prop--headline"
              dangerouslySetInnerHTML={{ __html: headline }}
              data-automationid="value_prop_headline"
            />
          )}
          {bodyCopy && (
            <WithEvents.div
              className="value-prop--body-copy"
              dangerouslySetInnerHTML={{ __html: bodyCopy }}
              data-automationid="value_prop_body"
            />
          )}
          {linkUrl && (
            <ValuePropButton
              {...{ [this.isHashLink ? 'data-target' : 'href']: linkUrl }}
              className="value-prop--link-option"
              dangerouslySetInnerHTML={{ __html: linkText }}
              onClick={this.hashScrollTo}
              data-automationid="value_prop_link_option"
            />
          )}
        </div>
      </div>
    );
  }
}

ValueProp.propTypes = {
  model: ValuePropModelSchema.isRequired,
  uniqueKey: PropTypes.number,
};

export default ValueProp;
