import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { scroller } from 'react-scroll';

import { CTAModelSchema } from '../model/schema';

import { CaretDownSVG } from '!app/components/assets/CaretDownSVG';
import {
  PLAN_BUILDER_URL,
  PLAN_BUILDER_HIT,
} from '!app/experimental/components/ExpPlanBuilderEscapeHatchV2/constants';
import {
  ANON,
  PARTIALLY_ENTITLED,
  FULLY_ENTITLED,
  STATUS_CODES,
} from '!app/lib/constants';
import { validateCodeFromSignup, getCtaText } from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import { ctaRedirect } from '!app/lib/urlUtils';
import {
  withUserInteraction,
  withUtagLink,
  WithEvents,
} from '!app/metrics/hoc';
import CTAButton from '!app/share/CTAButton';

import '../stylesheet/CallToActions.scss';

const INPUT_BOX_TYPE_CODE = 'code';
const INPUT_BOX_TYPE_EMAIL = 'email';

const CallToAction = (props) => {
  const {
    model: {
      bodyStates,
      disclaimer,
      emailCapture,
      eyebrow,
      headlineStates,
      image,
      isCodeRequired,
      primaryAction,
      primaryCta,
      primaryCtaStyle,
      primaryProgram,
      requiredCodeType,
      secondaryAction,
      secondaryCta,
      secondaryProgram,
      style,
    },
    cartAbandonment,
    ctaFields,
    locale,
    user,
  } = props;

  const { entitlementState } = user;
  const isCenterAligned = style && style.includes('center-aligned');
  const isDark = style && style.includes('dark');
  const callToActionClassNames = classNames(
    'container',
    { dark: isDark },
    'call-to-action-container'
  );
  const [codeError, setCodeError] = useState(false);
  const [code, setCode] = useState(null);
  const eventName = getEventNameFromEntitlement(user);

  let PrimaryDriverButton = withUserInteraction(
    CTAButton,
    typeof primaryAction?.[user?.entitlementState] === 'string' &&
      [PLAN_BUILDER_URL].includes(primaryAction?.[user?.entitlementState])
      ? PLAN_BUILDER_HIT
      : 'cta_primary'
  );
  PrimaryDriverButton = withUtagLink(
    {
      event_name: eventName,
      cta_placement: 'call_to_action_primary',
    },
    PrimaryDriverButton
  );

  let SecondaryDriverButton = withUserInteraction('button', 'cta_secondary');
  SecondaryDriverButton = withUtagLink(
    {
      event_name: eventName,
      cta_placement: 'call_to_action_secondary',
    },
    SecondaryDriverButton
  );

  /**
   * Sets states when user is typing into input box
   * @param {*} e
   */
  const onCodeInputChange = (e) => {
    setCodeError(false);
    setCode(e.target.value);
  };

  const onAnchorClick = async () => {
    const actionUrl = primaryAction?.[user.entitlementState];
    if (actionUrl && actionUrl.startsWith('#')) {
      scroller.scrollTo(actionUrl.replace('#', ''), {
        duration: 500,
        delay: 100,
        smooth: true,
        activeClass: 'active',
      });
    }
  };
  /**
   * Called when the primary button is clicked
   * Does logic for what type of action should be taken
   */
  const onSubmit = async () => {
    const actionUrl = primaryAction?.[user.entitlementState];

    const codeRequired = isCodeRequired && !(user && user.isHuluUser);
    if (codeRequired) {
      const type = requiredCodeType;
      const res = await validateCodeFromSignup(type, code);
      if (res.status === STATUS_CODES.OK) {
        redirect({ [`${type}_code}`]: code }, actionUrl, primaryProgram);
      } else {
        setCodeError(true);
      }
    } else if (Boolean(emailCapture) && !(user && user.isHuluUser)) {
      redirect({ email: code }, actionUrl, primaryProgram);
    } else {
      redirect(null, actionUrl, primaryProgram);
    }
  };

  /**
   * When secondary button is clicked it will redirect the user
   * @param {*} params
   */
  const onSecondarySubmit = () => {
    redirect(null, secondaryAction, secondaryProgram);
  };

  /**
   * If the path is signup redirect immediately or send to the goToSignup function
   * @param {*} params
   * @param {string} path
   */
  const redirect = (params, path, from) => {
    const ctaOptions = {
      params,
      user,
      componentUrl: path,
      ctaFields,
      from,
      cartAbandonment,
    };

    ctaRedirect(ctaOptions);
  };

  /**
   * Logic to determine what type of call to action should be displayed
   */
  const getInputBoxType = () => {
    // check if user already has subscription
    if (user && user.isSubscriber) {
      return null;
    }
    if (requiredCodeType) {
      return INPUT_BOX_TYPE_CODE;
    }
    if (emailCapture) {
      return INPUT_BOX_TYPE_EMAIL;
    }
    return null;
  };

  /**
   * Helper function to add placeholder text in input field
   */
  const getInputBoxPlaceholder = () => {
    const { model } = props;
    // check if user already has subscription
    if (user && user.isSubscriber) {
      return null;
    }
    return model.inputBoxPlaceholder;
  };

  /**
   * Helper function to add the correct text on the primary cta button
   */
  const getPrimaryCtaButtonText = () => {
    const isSubscriber = user && user.isSubscriber;
    let defaultCTA = primaryCta[ANON];
    if (!isSubscriber) {
      if (inputBoxType === INPUT_BOX_TYPE_CODE) {
        defaultCTA = 'Activate';
      } else if (inputBoxType === INPUT_BOX_TYPE_EMAIL) {
        defaultCTA = 'Sign up';
      }
    }

    const ctaOptions = {
      user,
      componentNonSubCta: defaultCTA,
      componentSubCta: primaryCta[PARTIALLY_ENTITLED],
      componentAllCta: primaryCta[FULLY_ENTITLED],
      locale,
      ctaFields,
      cartAbandonment,
    };

    return getCtaText(ctaOptions);
  };

  const renderComponent = (
    CustomComponent,
    className,
    dangerousHTML = false,
    statefulContent,
    automationId,
    id = ''
  ) => {
    const content = entitlementState
      ? statefulContent[entitlementState]
      : statefulContent[ANON];

    const componentProps = {
      className,
    };
    if (id) componentProps.id = id;
    if (dangerousHTML) {
      return (
        content && (
          <CustomComponent
            {...componentProps}
            data-automationid={automationId}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )
      );
    }

    return (
      content && (
        <CustomComponent {...componentProps} data-automationid={automationId}>
          {content}
        </CustomComponent>
      )
    );
  };

  const Eyebrow = () => {
    if (!eyebrow) return null;
    return (
      <Text
        as="p"
        variant="label14"
        className="eyebrow"
        data-automationid="cta_eyebrow"
      >
        {eyebrow}
      </Text>
    );
  };

  const inputBoxType = getInputBoxType();
  const inputBoxPlaceholder = getInputBoxPlaceholder();
  const primaryCtaButtonText = getPrimaryCtaButtonText();

  if (image) {
    /* Not sure how to test this */
    /* istanbul ignore next */
    const style_class = (style || '').replace('center-aligned', '');
    const { desktop, tablet, mobile } = image;

    return (
      <div
        id="call-to-action"
        className={classNames(
          'container',
          { dark: isDark },
          'cu-call-to-action',
          'call-to-action-container'
        )}
        data-automationid="call_to_action"
        role="region"
      >
        <div
          className={`container-width call-to-action call-to-action--with-image ${style_class}`}
        >
          <div className="col-lg-6 cta--column--texts">
            <Eyebrow />
            <Text
              as="h2"
              variant="title32"
              className="cta--headline section-headline"
              data-automationid="cta_headline"
            >
              {entitlementState
                ? headlineStates[entitlementState]
                : headlineStates[ANON]}
            </Text>
            {renderComponent(
              WithEvents.div,
              'cta--body',
              true,
              bodyStates,
              'cta_body'
            )}
            <div
              className={`cta--column--actions${
                inputBoxType != null ? '-with-input' : ''
              }`}
            >
              {inputBoxType != null && (
                <div
                  className={`cta--code-input-wrapper ${
                    !codeError ? '' : 'cta--code-input--invalid'
                  }`}
                >
                  <input
                    type={
                      inputBoxType === INPUT_BOX_TYPE_EMAIL ? 'email' : 'text'
                    }
                    className="cta--code-input"
                    placeholder={inputBoxPlaceholder}
                    onChange={onCodeInputChange}
                  />
                  <div className="cta--invalid-code-icon">!</div>
                </div>
              )}
              <PrimaryDriverButton
                useStyle={primaryCtaStyle}
                onClick={onSubmit}
              >
                {primaryCtaButtonText}
              </PrimaryDriverButton>
              {secondaryCta && inputBoxType === null && (
                <SecondaryDriverButton
                  className="button--cta secondary-button"
                  data-automationid="button_cta"
                  onClick={onSecondarySubmit}
                >
                  {secondaryCta}
                </SecondaryDriverButton>
              )}
            </div>
            {disclaimer && (
              <WithEvents.div
                className="cta__disclaimer"
                data-automationid="cta_disclaimer"
                dangerouslySetInnerHTML={{ __html: disclaimer }}
              />
            )}
          </div>
          <div className="col-lg-6 cta--image">
            <picture>
              <source media="(min-width: 1024px)" data-srcset={desktop.url} />
              <source media="(min-width: 768px)" data-srcset={tablet.url} />
              <source media="(min-width: 0px)" data-srcset={mobile.url} />
              <img
                className="lazyload"
                data-src={desktop.url}
                alt={desktop.alt}
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="call-to-action"
      className={callToActionClassNames}
      role="region"
      aria-labelledby="regionCallToAction"
      data-automationid="call_to_action"
    >
      <div className={`container-width call-to-action ${style}`}>
        <div
          className={`${isCenterAligned ? '' : 'col-lg-6'} cta--column--texts`}
        >
          <Eyebrow />
          {renderComponent(
            'h2',
            'cta--headline section-headline',
            false,
            headlineStates,
            'cta_headline',
            'regionCallToAction'
          )}
          {renderComponent(
            WithEvents.div,
            'cta--body',
            true,
            bodyStates,
            'cta_body'
          )}
        </div>

        <div
          className={`${
            isCenterAligned ? '' : 'col-lg-6'
          } cta--column--actions${inputBoxType != null ? '-with-input' : ''}`}
        >
          {inputBoxType != null && (
            <div
              className={`cta--code-input-wrapper ${
                codeError && 'cta--code-input--invalid'
              }`}
            >
              <input
                type={inputBoxType === INPUT_BOX_TYPE_EMAIL ? 'email' : 'text'}
                className="cta--code-input"
                placeholder={inputBoxPlaceholder}
                onChange={onCodeInputChange}
              />
              <div className="cta--invalid-code-icon">!</div>
            </div>
          )}

          <div className="cta__action-container">
            {style && style.includes('anchor-cta') ? (
              <CTAButton onClick={onAnchorClick} className="anchor-cta-button">
                <CaretDownSVG />
              </CTAButton>
            ) : (
              <PrimaryDriverButton
                useStyle={primaryCtaStyle}
                onClick={onSubmit}
              >
                {primaryCtaButtonText}
              </PrimaryDriverButton>
            )}
            {secondaryCta && inputBoxType === null && (
              <SecondaryDriverButton
                className="button--cta secondary-button"
                data-automationid="button_cta"
                onClick={onSecondarySubmit}
              >
                {secondaryCta}
              </SecondaryDriverButton>
            )}
            {disclaimer && (
              <WithEvents.div
                className="cta__disclaimer"
                data-automationid="cta_disclaimer"
                dangerouslySetInnerHTML={{ __html: disclaimer }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  model: CTAModelSchema,
  user: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  locale: PropTypes.string,
  cartAbandonment: PropTypes.shape({}),
};

export default CallToAction;
